<template>
  <div class="container task-details">
    <div class="columns is-mobile">
      <div class="column">
        <div class="header-title">
          {{ $t('tasks.taskDetails.headerTitle', { id: task.orderIdentifier }) }}
        </div>
      </div>
      <div class="column">
        <div class="task-actions">
          <mark-as-selector
            position="is-bottom-left"
            @change="onMarkAs"/>
          <b-button
            tag="a"
            :href="`${env.VUE_APP_SPINE_URL}/admin/orders?search=${task.orderIdentifier}`"
            target="_blank">
            {{ $t('tasks.taskDetails.edit') }}
          </b-button>
          <template v-if="task.externalLogisticsId">
            <b-button
              v-if="task.externalLogisticsType === 'pickupp'"
              tag="a"
              :href="`${env.VUE_APP_PICKUPP_URL}/orders/${task.externalLogisticsId}`"
              target="_blank">
              {{ $t('tasks.taskDetails.checkPickupp') }}
            </b-button>
            <b-button
              v-else-if="task.externalLogisticsType === 'lalamove'"
              tag="a"
              :href="task.externalLogisticsTrackingUrl"
              target="_blank">
              {{ $t('tasks.taskDetails.checkLalamove') }}
            </b-button>
          </template>
          <b-button
            v-if="findTaskLink"
            tag="a"
            :href="findTaskLink"
            target="_blank">
            Find task
          </b-button>
        </div>
      </div>
    </div>
    <div class="columns is-mobile">
      <div class="column">
        <div class="card task-info">
          <div class="card-content">
            <b-taglist class="task-tags">
              <!-- TODO: if on_courier, show eta including tasks in front -->
              <task-status :task="task"/>
              <b-tag
                v-if="task.isPickup"
                type="is-dark">
                {{ $t('tasks.taskDetails.info.tags.pickup') }}
              </b-tag>
              <b-tag type="is-dark">
                {{ task.hubShortCode.split('-')[0] }}
              </b-tag>
            </b-taglist>
            <div class="task-info-group serving-time">
              <div class="header">
                {{ $t('tasks.taskDetails.info.servingDateTime.label') }}
              </div>
              <div class="content">
                {{ servingDateTime }}
              </div>
            </div>
            <div class="task-info-group courier-details">
              <div class="header">
                {{ $t('tasks.taskDetails.info.courier.label') }}
              </div>
              <div class="content">
                {{ courierName }}
                <template v-if="courierContact">
                  •
                  {{ courierContact }}
                </template>
              </div>
            </div>
            <div class="task-info-group fulfilment-address">
              <div class="header">
                {{ $t('tasks.taskDetails.info.address.label') }}
              </div>
              <div class="content">
                {{ task.deliveryAddress || task.pickupPointName }}
              </div>
            </div>
            <div class="task-info-group customer-details">
              <div class="header">
                {{ $t('tasks.taskDetails.info.customer.label') }}
              </div>
              <div class="content">
                {{ task.customerName }}
                •
                {{ task.customerContactNumber }}
                <br>
                {{ task.customerEmail }}
              </div>
            </div>
            <div class="task-info-group order-items">
              <div class="header">
                {{ $t('tasks.taskDetails.info.items.label') }}
              </div>
              <div class="content">
                <div
                  v-for="item in orderItems"
                  :key="`order_item_${item.id}`"
                  class="order-item">
                  {{ $t('tasks.taskDetails.info.items.content', { name: item.bundleTemplateName, quantity: item.multiplierValue }) }}
                  <ul v-if="item.meals.length > 1">
                    <li
                      v-for="meal in item.meals"
                      :key="`order_item_${item.id}_meal_${meal.bundleTemplateSlotId}_${meal.id}`">
                      {{ meal.name }}
                      <template v-if="meal.quantity > 1">
                        {{ $t('tasks.taskDetails.info.items.meals.quantity', { quantity: meal.quantity }) }}
                      </template>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <task-details-map
          v-if="!task.externalLogisticsId"
          :tasks="tripTasks"
          :selectedTask="task"
          :trip="trip"/>
        <div class="card task-fulfilment-info">
          <div class="card-content">
            <ul v-if="tripTasks.length > 0">
              <li
                v-for="tripTask in tripTasks"
                :key="`trip_task_${tripTask.orderIdentifier}`"
                :class="{'selected': tripTask.orderIdentifier === task.orderIdentifier}">
                <router-link :to="{ name: 'tasks', params: { id: tripTask.orderIdentifier } }">
                  <div class="trip-task-order-number">
                    {{ tripTask.orderIdentifier }}
                    <b-tag
                      v-if="tripTask.completedAt"
                      type="is-dark"
                      rounded
                      class="trip-task-completed-at">
                      {{ $t('tasks.status.completedAt', { time: formatTimeFromDate(tripTask.completedAt) }) }}
                    </b-tag>
                  </div>
                  <div class="trip-task-timeslot">
                    {{ tripTask.rangeLabel }}
                  </div>
                  <div class="trip-task-fulfilment-address">
                    {{ tripTask.deliveryAddress || tripTask.pickupPointName }}
                  </div>
                </router-link>
              </li>
            </ul>
            <template v-if="!courierName">
              {{ $t('tasks.taskDetails.unassigned') }}
            </template>
            <template v-if="task.comments">
              <hr>
              <task-comments :task="task"/>
            </template>
          </div>
        </div>
      </div>
      <b-loading :active="isCreateCommentLoading"/>
    </div>
  </div>
</template>

<script>
import { formatDate, formatTimeFromDate, isSystemAccount, secondsTimeRangeForSelector } from '@js/utils'
import { mapActions, mapGetters } from 'vuex'
import markTasksAsMixin from '@/mixins/mark-tasks-as-mixin'

const MarkAsSelector = () => import('@components/MarkAsSelector')
const TaskComments = () => import('./TaskComments')
const TaskDetailsMap = () => import('./TaskDetailsMap')
const TaskStatus = () => import('@components/TaskStatus')

export default {
  name: 'task-details',
  components: {
    MarkAsSelector,
    TaskComments,
    TaskDetailsMap,
    TaskStatus
  },
  mixins: [markTasksAsMixin],
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('api', [
      'getLoading'
    ]),
    ...mapGetters('trip', [
      'trip'
    ]),
    env() {
      return process.env
    },
    isCreateCommentLoading() {
      return this.getLoading('task/createTaskComment')
    },
    taskTrip() {
      if (this.trip && this.trip.id === this.task.tripId) {
        return this.trip
      }
      return null
    },
    servingDateTime() {
      return this.$t('tasks.taskDetails.info.servingDateTime.content', {
        time: this.task.rangeLabel,
        date: formatDate(this.task.servingDate, {
          yearFirst: false,
          separator: '/'
        })
      })
    },
    courier() {
      return this.taskTrip && this.taskTrip.fulfilmentUser
    },
    courierName() {
      if (this.courier) {
        return this.courier.name
      }
      return null
    },
    courierContact() {
      if (this.courier && !isSystemAccount(this.courier)) {
        return this.courier.mobileNumber
      }
      return null
    },
    orderItems() {
      return this.task.orderItems || []
    },
    tripTasks() {
      if (this.taskTrip) {
        return this.taskTrip.tasks
      }
      return [this.task]
    },
    findTaskLink() {
      if (this.task.state === 'incoming') {
        return `/assignment?date=${this.task.servingDate}&time=${secondsTimeRangeForSelector(this.task.startTime)}&hub=${this.task.hubId}`
      } else if (this.task.state === 'assigned') {
        return `/print?date=${this.task.servingDate}&time=${secondsTimeRangeForSelector(this.task.trip.leaveBy)}&hub=${this.task.hubId}`
      }
      return null
    }
  },
  created() {
    if (this.task.tripId && !this.taskTrip) {
      this.getTripRequest(this.task.tripId)
    }
  },
  methods: {
    ...mapActions('trip', [
      'getTripRequest'
    ]),
    onMarkAs(option) {
      this.markTasksAs([this.task], option)
    },
    formatTimeFromDate(date) {
      return formatTimeFromDate(date)
    }
  }
}
</script>

<style lang="scss" scoped>
.task-details {
  max-width: 900px;
  margin-top: $space-xl;

  @include touch {
    padding-right: $space-s;
    padding-left: $space-s;
  }

  .columns + .columns {
    > .column {
      padding-top: 0;
    }
  }

  .header-title {
    @extend %display_medium;
  }

  .task-actions {
    text-align: right;

    &::v-deep .button {
      font-weight: bold;
      color: $primary;
      background-color: transparent;
      border: 1px solid $primary;
      border-radius: 2px;
    }

    > :not(:first-child) {
      margin-left: $space-xs;
    }
  }

  .task-info {
    background-color: $white;

    .task-tags {
      margin-bottom: 0;
    }

    .task-info-group {
      &:not(:last-child) {
        padding-bottom: $space-s;
        margin-bottom: $space-s;
        border-bottom: 1px solid $grey-light;
      }

      .header {
        margin-bottom: $space-xxs;
        font-weight: bold;
        color: $grey-dark;
        @extend %small;
      }

      .content {
        color: $black;
      }
    }

    .order-items {
      .order-item {
        &:not(:last-child) ul {
          margin-bottom: $space-xs;
        }

        ul {
          margin-top: $space-xxs;
          @extend %small;
        }
      }
    }
  }

  .task-details-map {
    margin-bottom: $space-xs;
  }

  .task-fulfilment-info {
    color: $primary-ink;

    ul {
      padding-left: 20px;
      @extend %small;

      li {
        position: relative;
        padding-bottom: $space-s;

        &::before,
        &:not(:last-child)::after {
          position: absolute;
          top: 4px;
          display: block;
          content: '';
          background-color: $grey-light;
        }

        &::before {
          left: -16px;
          z-index: 1;
          width: 8px;
          height: 8px;
          border-radius: $radius-rounded;
        }

        &:not(:last-child)::after {
          left: -13px;
          width: 1px;
          height: 100%;
        }

        &.selected {
          a {
            color: $primary;
          }

          &::before {
            background-color: $primary;
          }
        }

        &:not(.selected) {
          a {
            color: $primary-ink;

            &:hover {
              color: $primary;
            }
          }
        }

        .trip-task-completed-at {
          float: right;
          height: 18px; // tag line-height
          margin-left: $space-xxs;
          font-weight: normal;
        }

        .trip-task-order-number {
          font-weight: bold;
        }
      }
    }

    hr {
      height: 1px;
      margin: $space-m 0;
      background-color: $grey;
    }
  }

  .button {
    @extend %body;
  }
}
</style>
